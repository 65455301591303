// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** UWF color palate by name **/
  // --uwf-blue: #004C97;
  // --uwf-green: #007A33;
  // --nautilus-blue: #009CDE;
  // --luna-blue: #8DC8E8;
  // --canon-green: #43B02A;
  // --spring-green: #97C800;
  // --armadillo-gray: #666666;
  // --light-cool-gray: #B2B9BF;

  /** Ionic specific colors **/
  --ion-color-primary: #004C97; // uwf blue
  --ion-color-primary-rgb: 0,76,151;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #004385;
  --ion-color-primary-tint: #1a5ea1;

  --ion-color-secondary: #004C97; // nautilus blue
  --ion-color-secondary-rgb: 0,156,222;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #004385;
  --ion-color-secondary-tint: #1a5ea1;

  --ion-color-tertiary: #8DC8E8; // luna blue
  --ion-color-tertiary-rgb: 0,76,151;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #004385;
  --ion-color-tertiary-tint: #1a5ea1;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #2A1704;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #d12f2c;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #757575;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-featured: #FFE780;
  --ion-color-featured-rgb: 255,206,0;
  --ion-color-featured-contrast: #2A1704;
  --ion-color-featured-contrast-rgb: 255,255,255;
  --ion-color-featured-shade: #FFF0B3;
  --ion-color-featured-tint: #FFDD4D;

  --ion-color-directory: #F0F5FE;
  --ion-color-directory-rgb: 224,235,253;
  --ion-color-directory-contrast: #004C97;
  --ion-color-directory-contrast-rgb: 0,76,151;
  --ion-color-directory-shade: #CBDDFC;
  --ion-color-directory-tint: #E9F1FE;
}
