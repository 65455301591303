// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@ionic/angular/css/display.css';

// Support for iphone X notch
body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.announcement-body a {color: var(--uwf-announcement-contrast) !important;}

/* Spinner (loading) */
.spinner {
  color: var(--ion-color-primary);
  width: 5rem;
  height: 5rem;
}

.spinner-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.popover-content { min-width: 360px !important; }

// Declare a minimum height for all popovers
.notifications-popover .popover-content,
.search-popover .popover-content,
.profile-popover .popover-content {
  min-height: 120px;
}
@media only screen and (min-width: 768px) {
  
  .search-modal, .profile-modal, .notifications-modal {
    &::part(content){
      position: absolute;
      top: 0;
      right: 0;
      height: 95% !important;
      width: 620px;
    }
  }
}

// Custom color schemes
.ion-color-featured {
  --ion-color-base: var(--ion-color-featured);
  --ion-color-base-rgb: var(--ion-color-featured-rgb);
  --ion-color-contrast: var(--ion-color-featured-contrast);
  --ion-color-contrast-rgb: var(--ion-color-featured-contrast-rgb);
  --ion-color-shade: var(--ion-color-featured-shade);
  --ion-color-tint: var(--ion-color-featured-tint);
}

.ion-color-directory {
  --ion-color-base: var(--ion-color-directory);
  --ion-color-base-rgb: var(--ion-color-directory-rgb);
  --ion-color-contrast: var(--ion-color-directory-contrast);
  --ion-color-contrast-rgb: var(--ion-color-directory-contrast-rgb);
  --ion-color-shade: var(--ion-color-directory-shade);
  --ion-color-tint: var(--ion-color-directory-tint);
}
